<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <div class="vx-col w-full">
        <label class="vs-input--label">Bank Name</label>
        <vs-input
          class="w-full input-readonly"
          v-model="selected.BankName"
        />
          <!-- disabled-dates="{from: new Date()}" -->
    </div>
    <div class="vx-col w-full">
        <label class="vs-input--label">Branch Code</label>
        <vs-input
          class="w-full input-readonly"
          v-model="selected.BranchCode"
        />
          <!-- disabled-dates="{from: new Date()}" -->
    </div>
    <div class="vx-col w-full">
        <label class="vs-input--label">Txn Date</label>
        <vs-input
          class="w-full input-readonly"
          :value="dateFormat(selected.TxnDate)"
        />
          <!-- disabled-dates="{from: new Date()}" -->
    </div>
    <div class="vx-col w-full">
        <label class="vs-input--label">Reference Code</label>
        <vs-input
          class="w-full input-readonly"
          :value="(selected.ReferenceCode)"
        />
          <!-- disabled-dates="{from: new Date()}" -->
    </div>
    <div class="vx-col w-full">
        <label class="vs-input--label">No Giro</label>
        <vs-input
          class="w-full input-readonly"
          :value="(selected.NoGiro)"
        />
          <!-- disabled-dates="{from: new Date()}" -->
    </div>
    <div class="vx-col w-full">
        <label class="vs-input--label">Name Va</label>
        <vs-input
          class="w-full input-readonly"
          v-model="selected.NameVa"
        />
          <!-- disabled-dates="{from: new Date()}" -->
    </div>
    <vs-divider style="width:100%;margin-left:2%">Suggestion</vs-divider>
    <div v-if="suggestion.length < 1" class="vx-col w-full">
      <vs-alert color="danger">
        Not Found Suggestion
      </vs-alert>
    </div>
    <div v-else>
      <vs-card v-bind:key="indextr" v-for="(tr,indextr) in suggestion">
        <div slot="header">
          <h6>
            {{tr.Code}}
          </h6>
        </div>
        <div>
          <span>
            Method : {{methods[tr.Method]}}<br>
            Ref Code : {{tr.ReferenceCode}}<br>
            <template v-if="tr.GiroBankID!=0">
              Customer : {{ '(' + tr.PersonCode + ') ' + tr.PersonName}} <br>
              {{methods[tr.Method]}} Bank : {{tr.GiroBankName}}<br>
              {{methods[tr.Method]}} Number : {{tr.GiroNumber}}<br>
              {{methods[tr.Method]}} Date : {{dateFormat(tr.GiroDate)}}<br>
            </template>
          </span>
        </div>
        <div slot="footer">
          <vs-row vs-justify="flex-end">
            <vs-button type="gradient" color="danger" v-on:click="handleReconcile(tr)">reconcile</vs-button>
          </vs-row>
        </div>
      </vs-card>
    </div>
    <vs-divider style="width:100%;margin-left:2%">Other</vs-divider>
    <div class="vx-col w-full">
      <vs-card v-bind:key="indextr" v-for="(tr,indextr) in other">
        <div slot="header">
          <h6>
            {{tr.Code}}
          </h6>
        </div>
        <div>
          <span>
            Method : {{methods[tr.Method]}}<br>
            Ref Code : {{tr.ReferenceCode}}<br>
            <template v-if="tr.GiroBankID!=0">
              Customer : {{ '(' + tr.PersonCode + ') ' + tr.PersonName}} <br>
              {{methods[tr.Method]}} Bank : {{tr.GiroBankName}}<br>
              {{methods[tr.Method]}} Number : {{tr.GiroNumber}}<br>
              {{methods[tr.Method]}} Date : {{dateFormat(tr.GiroDate)}}<br>
            </template>
          </span>
        </div>
        <div slot="footer">
          <vs-row vs-justify="flex-end">
            <vs-button type="gradient" color="danger" v-on:click="handleReconcile(tr)">reconcile</vs-button>
          </vs-row>
        </div>
      </vs-card>
      <vs-button v-if="other.length < optionOther.length " color="primary" type="filled" v-on:click="loadMore" >Load More </vs-button>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment"

export default {
  components: {
    Datepicker,
  },
  created() {},
  data() {
    return this.initialState();
  },
  props: {
    selected: Object,
  },
  methods: {
    initialState() {
      return {
        methods:['','Cash','Giro','Cheque','Transfer'],
        suggestion:[],
        suggestID:[],
        other:[],
        optionOther: [],
      };
    },
    formatPrice(angka) {
      return angka.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    getSuggestion(){
      const params = {
        search : this.selected.ReferenceCode
      };
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.suggestion = r.data.cashBank;
          this.suggestID = []
          this.suggestion.map(r => {
            this.suggestID.push(r.ID)
          })
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    handleReconcile(tr) {
      console.log(tr)
    },
    loadMore() {
      // this.$vs.loading();
      this.optionOther.map((r,i) => {
        if (i >= this.other.length && i <= (parseInt(this.other.length) +parseInt(10))   ) {
          if (!this.suggestID.includes(r.ID)) {
            this.other.push(r)
          }
        }
      })
    },
    getOther(){
      const params = {
        search : ""
      };
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.optionOther = r.data.cashBank;
          this.optionOther.map((r,i) => {
            if (i < 2) {
              if (!this.suggestID.includes(r.ID)) {
                this.other.push(r)
              }
            }
          })
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http.get('/api/v1/cash-bank/data-table',{
            params: {
                status: 99,
                search: params.search,
                length: 10000,
                page: 1,
                order: "id",
                sort: "desc",
            }
        }).then((r) => {
          resolve(r)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    
  },
  mounted() {
    console.log(this.selected)
    console.log("mounted")
    if (this.selected.ReferenceCode != "") {
      this.getSuggestion()
    } 
    this.getOther()
  },
  computed: {},
  watch: {
    "create.method"() {
      
    },
    "selected.ID"() {
      this.$nextTick(() => {
        console.log(this.selected)
      })
    }
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>