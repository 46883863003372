<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}

.bg-dark {
  background-color: #e6e6e6 !important;
}

.bg-green {
  background-color: #06fc27 !important;
}

.fullpage-div {
  height: 80vh;
  /* background-color:#FFFFFF; */
}

.div-scroll {
  /* background-color: lightblue; */
  /* max-width: 150px; */
  height: 80%;
  overflow: auto;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <div>
      <div v-if="listRecon.length > 0">
        <vs-button type="gradient" color="danger" v-on:click="handleReconcile()"
          >Reconcile</vs-button
        >
        <vs-divider style="width: 100%; margin-left: 2%"></vs-divider>
        Transaction Value : {{ formatPrice(this.selected.TransactionValue)
        }}<br />
        Selected Value : {{ priceFormat(this.totalSelected) }}<br />
        Remaining Value :
        {{ this.remainingValue }}
        <div
          class="mb-2 vx-row mt-8"
          v-for="(input, k) in inputs"
          :key="k.toString() + 'ABC'"
        >
          <div class="w-1/4 vx-col">
            <label class="vs-input--label">COA</label>
            <multiselect
              class="selectExample"
              v-model="input.coa"
              :options="optionCoa"
              :multiple="false"
              :allow-empty="true"
              :group-select="false"
              :max-height="160"
              :limit="4"
              placeholder=" Type to search"
              track-by="Code"
              :custom-label="queryCoa"
              @search-change="onCoaSearch"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ dt.option.Code }} - {{ dt.option.Name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ dt.option.Code }} - {{ dt.option.Name }}</span
                  >
                </div>
              </template>
            </multiselect>
          </div>

          <div class="w-1/4 vx-col">
            <label class="vs-input--label">Cost Center</label>
            <multiselect
              class="selectExample"
              v-model="input.cost_center"
              :options="optionCostCenter"
              :multiple="false"
              :allow-empty="true"
              :group-select="false"
              :max-height="160"
              :limit="4"
              placeholder=" Type to search"
              track-by="Name"
              label="Name"
              @search-change="onCostCenterSearch"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.Name }}</span>
                </span>
              </template>

              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.Name }}</span>
                </div>
              </template>
            </multiselect>
          </div>

          <div class="w-1/4 vx-col">
            <label class="vs-input--label">Value</label>
            <!--@keypress="isNumber($event)" -->
            <vs-input
              class="w-full"
              v-model="input.additional_value"
              @input="calculateAdditionaTotalValue"
              @keyup="onFinishIput(k)"
            />

            <!-- <vs-input /> -->
          </div>

          <div class="w-1/4 vx-col">
            <label class="vs-input--label">Territory</label>
            <multiselect
              class="selectExample"
              v-model="input.territory"
              :options="optionTerritory"
              :multiple="false"
              :allow-empty="true"
              :group-select="false"
              :max-height="160"
              :limit="4"
              placeholder="Select Territory"
              track-by="TerritoryID"
              label="TerritoryName"
              @select="onChangeOU"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title"
                    >({{ dt.option.TerritoryCode }}),
                    {{ dt.option.TerritoryName }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title"
                    >({{ dt.option.TerritoryCode }}),
                    {{ dt.option.TerritoryName }}</span
                  >
                </div>
              </template>
            </multiselect>

            <div class="whitespace-no-wrap">
              <div class="flex mt-4 mr-1">
                <vs-button
                  style="margin-right: 5px"
                  size="small"
                  @click="removeField(k)"
                  icon-pack="feather"
                  icon="icon-minus"
                  v-show="k || (!k && inputs.length > 1)"
                >
                </vs-button>
                <vs-button
                  style="margin-right: 5px"
                  size="small"
                  @click="addField(k)"
                  icon-pack="feather"
                  icon="icon-plus"
                  v-show="k == inputs.length - 1"
                >
                </vs-button>
              </div>
            </div>
          </div>
        </div>
        <vs-divider style="width: 100%; margin-left: 2%"
          >List Reconcile</vs-divider
        >
        <TransitionGroup name="list" tag="ul">
          <li v-for="(item, i) in detailListRecon" :key="i">
            <vs-button
              type="line"
              icon-pack="feather"
              icon="icon-trash"
              v-on:click.stop="unReconcile(item.type, item.row)"
            />
            {{
              item.type == "payment"
                ? "Payment"
                : item.type == "cash-deposit"
                ? "Cash Deposit"
                : item.type == "customer-deposit"
                ? "Customer Deposit"
                : "Bank Statement"
            }}<br />
            <span v-if="item.type == 'cash-deposit'">
              Method : {{ methods[item.row.Method] }}<br />
              Ref Code : {{ item.row.ReferenceCode }}<br />
              Deposit Number : {{ item.row.DepositNumber }}<br />
              Deposit Value : {{ priceFormat(item.row.DepositValue) }}<br />
              Bank : {{ item.row.BankName }}<br />
              Account Name : {{ item.row.AccountName }}<br />
              Account Number : {{ item.row.AccountNumber }}<br />
              <template v-if="item.row.GiroBankID != 0">
                Customer :
                {{ "(" + item.row.PersonCode + ") " + item.row.PersonName }}
                <br />
                {{ methods[item.row.Method] }} Bank : {{ item.row.GiroBankName
                }}<br />
                {{ methods[item.row.Method] }} Number : {{ item.row.GiroNumber
                }}<br />
                {{ methods[item.row.Method] }} Date :
                {{ dateFormat(item.row.GiroDate) }}<br />
              </template>
            </span>
            <span v-if="item.type == 'customer-deposit'">
              Method : {{ methods[item.row.Method] }}<br />
              Ref Code : {{ item.row.ReferenceCode }}<br />
              Deposit Number : {{ item.row.DepositNumber }}<br />
              Deposit Value : {{ priceFormat(item.row.DepositValue) }}<br />
              Bank : {{ item.row.BankName }}<br />
              Account Name : {{ item.row.AccountName }}<br />
              Account Number : {{ item.row.AccountNumber }}<br />
              Customer :
              {{ "(" + item.row.PersonCode + ") " + item.row.PersonName }}
              <br />
              <template v-if="item.row.GiroBankID != 0">
                {{ methods[item.row.Method] }} Bank : {{ item.row.GiroBankName
                }}<br />
                {{ methods[item.row.Method] }} Number : {{ item.row.GiroNumber
                }}<br />
                {{ methods[item.row.Method] }} Date :
                {{ dateFormat(item.row.GiroDate) }}<br />
              </template>
            </span>
            <span v-if="item.type == 'payment'">
              Method : {{ item.row.PaymentMethod }}<br />
              Ref Code : {{ item.row.ReferenceCode }}<br />

              Payment Value : {{ priceFormat(item.row.Amount) }}<br />
              <template v-if="item.row.GiroBankID != 0">
                Customer :
                {{ "(" + item.row.CustomerCode + ") " + item.row.CustomerName }}
                <br />
                Bank : {{ item.row.BankName }} ({{
                  item.row.BankAccountNumber
                }}
                - {{ item.row.BankAccountName }})<br />
              </template>
            </span>
            <span v-if="item.type == 'bank-statement'">
              Branch Code : {{ item.row.BranchCode }} <br />
              Statement Code :{{ item.row.BankStatement.Code }}<br />
              Payment Value : {{ priceFormat(item.row.TransactionValue) }}<br />
              Name VA : {{ item.row.NameVa }} <br />
              Reference Code :
              {{ item.row.ReferenceCode ? item.row.ReferenceCode : "-" }}<br />
              No Giro : {{ item.row.NoGiro ? item.row.NoGiro : "-" }}<br />
              Remark : {{ item.row.Note }}<br />
              Txn Date : {{ dateFormat(item.row.TxnDate) }}<br />
              <template v-if="item.row.CashBankID != 0">
                Cash Bank Code : {{ item.row.CashBankCode }}
              </template>
            </span>
            <span v-if="type == 'supplier-payment'">
              Code : {{ item.row.Code }}<br />
              No Giro : {{ item.row.GiroNumber ? item.row.GiroNumber : "-"
              }}<br />
              Supplier :
              {{ "(" + item.row.PartnerCode + ") " + item.row.PartnerName }}
              <br />
              Bank : {{ item.row.BankName }} ({{ item.row.BankAccountNumber }} -
              {{ item.row.BankAccountName }})<br />
              Payment Value : {{ priceFormat(item.row.Amount) }}<br />
            </span>
            <vs-divider style="width: 100%; margin-left: 2%"></vs-divider>
          </li>
        </TransitionGroup>
      </div>
    </div>
    <div class="vx-col w-full">
      <vs-radio
        v-model="type"
        vs-value="cash-deposit"
        class="mr-4"
        vs-name="type"
        >Cash Deposit</vs-radio
      >
      <vs-radio v-model="type" vs-value="payment" class="mr-4" vs-name="type"
        >Transfer / Cheque</vs-radio
      >
      <vs-radio
        v-model="type"
        vs-value="supplier-payment"
        class="mr-4"
        vs-name="type"
        >Supplier Payment</vs-radio
      >
      <vs-radio
        v-model="type"
        vs-value="customer-deposit"
        class="mr-4"
        vs-name="type"
        >Customer Deposit</vs-radio
      >
      <!-- <vs-radio v-model="type" vs-value="supplier-payment" class="mr-4" vs-name="footer-type-static">Supplier Payment</vs-radio> -->

      <vs-radio
        v-model="type"
        vs-value="bank-statement"
        class="mr-4"
        vs-name="type"
        >Bank Statement</vs-radio
      >
    </div>
    <vs-popup title="" :active.sync="detail">
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <div class="fullpage-div">
            <div class="div-scroll">
              <template>
                <vs-card
                  v-bind:key="indextr"
                  v-for="(invoice, indextr) in invoices.CashBankManagementLine"
                >
                  <div slot="header">
                    <h6>
                      Customer : {{ invoice.Payment.CustomerName }} ({{
                        invoice.Payment.CustomerCode
                      }}) <br />
                      Invoice : {{ invoice.Payment.PaymentLine[0].InvoiceCode
                      }}<br />
                      Number : {{ invoice.PaymentNumber }} <br />
                      Method : {{ invoice.PaymentMethod }} <br />
                      Value : {{ priceFormat(invoice.PaymentValue) }} <br />
                    </h6>
                  </div>
                  <div></div>
                </vs-card>
              </template>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>

    <!-- <vs-divider style="width:100%;margin-left:2%">Suggestion</vs-divider>
    <div v-if="suggestion.length < 1" class="vx-col w-full">
      <vs-alert color="danger">
        Not Found Suggestion
      </vs-alert>
    </div>
    <div v-else>
      <vs-card v-bind:key="indextr" v-for="(tr,indextr) in suggestion">
        <div slot="header">
          <h6>
            {{tr.Code}}
          </h6>
        </div>
        <div>
          <span>
            Method : {{methods[tr.Method]}}<br>
            Ref Code : {{tr.ReferenceCode}}<br>
            <template v-if="tr.GiroBankID!=0">
              Customer : {{ '(' + tr.PersonCode + ') ' + tr.PersonName}} <br>
              {{methods[tr.Method]}} Bank : {{tr.GiroBankName}}<br>
              {{methods[tr.Method]}} Number : {{tr.GiroNumber}}<br>
              {{methods[tr.Method]}} Date : {{dateFormat(tr.GiroDate)}}<br>
            </template>
          </span>
        </div>
        <div slot="footer">
          <vs-row vs-justify="flex-end">
            <vs-button type="gradient" color="danger" v-on:click="handleReconcile(tr)">reconcile</vs-button>
          </vs-row>
        </div>
      </vs-card>
    </div> -->
    <vs-divider style="width: 100%; margin-left: 2%">Option</vs-divider>
    <div class="vx-col w-full">
      <card-data-table
        :responseData="responseData"
        :propsParams="params"
        @reloadDataFromChild="reloadData"
      >
        <template slot="tbody">
          <vs-card v-bind:key="indextr" v-for="(tr, indextr) in optionOther">
            <div slot="header">
              <div class="vx-row" style="padding-left: 10px">
                <div
                  v-if="
                    type == 'cash-deposit' &&
                    tr.DepositValue == selected.TransactionValue
                  "
                >
                  <div style="width: 5%">
                    <feather-icon
                      icon="CheckIcon"
                      svgClasses="h-6 w-6 stroke-current bg-green"
                      class="block"
                    />
                  </div>
                </div>
                <div
                  v-if="
                    type == 'customer-deposit' &&
                    tr.DepositValue == selected.TransactionValue
                  "
                >
                  <div style="width: 5%">
                    <feather-icon
                      icon="CheckIcon"
                      svgClasses="h-6 w-6 stroke-current bg-green"
                      class="block"
                    />
                  </div>
                </div>
                <div
                  v-if="
                    type == 'payment' && tr.Amount == selected.TransactionValue
                  "
                >
                  <div style="width: 5%">
                    <feather-icon
                      icon="CheckIcon"
                      svgClasses="h-6 w-6 stroke-current bg-green"
                      class="block"
                    />
                  </div>
                </div>
                <div
                  v-if="
                    type == 'bank-statement' &&
                    tr.TransactionValue == selected.TransactionValue
                  "
                >
                  <div style="width: 5%">
                    <feather-icon
                      icon="CheckIcon"
                      svgClasses="h-6 w-6 stroke-current bg-green"
                      class="block"
                    />
                  </div>
                </div>
                <div
                  v-if="
                    type == 'supplier-payment' &&
                    tr.Amount == selected.TransactionValue
                  "
                >
                  <div style="width: 5%">
                    <feather-icon
                      icon="CheckIcon"
                      svgClasses="h-6 w-6 stroke-current bg-green"
                      class="block"
                    />
                  </div>
                </div>
                <div style="width: 80%">
                  <h6 style="left-margin: 1%">
                    {{ tr.Code }}
                  </h6>
                </div>
              </div>
            </div>
            <div>
              <span v-if="type == 'cash-deposit'">
                Method : {{ methods[tr.Method] }}<br />
                Ref Code : {{ tr.ReferenceCode }}<br />
                Deposit Number : {{ tr.DepositNumber }}<br />
                Deposit Value : {{ priceFormat(tr.DepositValue) }}<br />
                Bank : {{ tr.BankName }}<br />
                Account Name : {{ tr.AccountName }}<br />
                Account Number : {{ tr.AccountNumber }}<br />
                <!-- {{tr.CashBankManagementLine}} -->
                <template v-if="tr.GiroBankID != 0">
                  Customer : {{ "(" + tr.PersonCode + ") " + tr.PersonName }}
                  <br />
                  {{ methods[tr.Method] }} Bank : {{ tr.GiroBankName }}<br />
                  {{ methods[tr.Method] }} Number : {{ tr.GiroNumber }}<br />
                  {{ methods[tr.Method] }} Date : {{ dateFormat(tr.GiroDate)
                  }}<br />
                </template>
                <!-- <vx-tooltip text="Detail Mapping" class="mr-4"  >
      <card-data-table :responseData="responseData" :propsParams="params" @reloadDataFromChild="reloadData">
        <template slot="tbody">
          <vs-card v-bind:key="indextr" v-for="(tr, indextr) in optionOther">
            <div slot="header">
              <div class="vx-row" style="padding-left:10px">
                <div style="width:80%;">
                  <h6 style="left-margin:1%">
                    {{ tr.Code }}
                  </h6>
                </div>
              </div>
            </div>
            <div>

              <span v-if="type == 'cash-deposit'">
                Method : {{ methods[tr.Method]}}<br>
                Ref Code : {{ tr.ReferenceCode }}<br>
                Deposit Number : {{ tr.DepositNumber }}<br>
                Deposit Value : {{ priceFormat(tr.DepositValue) }}<br>
                Bank : {{ tr.BankName }}<br>
                Account Name : {{ tr.AccountName }}<br>
                Account Number : {{ tr.AccountNumber }}<br>
                {{tr.CashBankManagementLine}} 
                <template v-if="tr.GiroBankID != 0">
                  Customer : {{ '(' + tr.PersonCode + ') ' + tr.PersonName }} <br>
                  {{ methods[tr.Method]}} Bank : {{ tr.GiroBankName }}<br>
                  {{ methods[tr.Method]}} Number : {{ tr.GiroNumber }}<br>
                  {{ methods[tr.Method]}} Date : {{ dateFormat(tr.GiroDate) }}<br>
                </template>
                 <vx-tooltip text="Detail Mapping" class="mr-4"  >
                  <vs-button
                  v-on:click="showDetail(tr)"
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                  />
                </vx-tooltip> -->
              </span>
              <span v-if="type == 'customer-deposit'">
                Method : {{ methods[tr.Method] }}<br />
                Ref Code : {{ tr.ReferenceCode }}<br />
                Deposit Number : {{ tr.DepositNumber }}<br />
                Deposit Value : {{ priceFormat(tr.DepositValue) }}<br />
                Bank : {{ tr.BankName }}<br />
                Account Name : {{ tr.AccountName }}<br />
                Account Number : {{ tr.AccountNumber }}<br />
                Customer : {{ "(" + tr.PersonCode + ") " + tr.PersonName }}
                <br />
                <!-- {{tr.CashBankManagementLine}} -->
                <template v-if="tr.GiroBankID != 0">
                  {{ methods[tr.Method] }} Bank : {{ tr.GiroBankName }}<br />
                  {{ methods[tr.Method] }} Number : {{ tr.GiroNumber }}<br />
                  {{ methods[tr.Method] }} Date : {{ dateFormat(tr.GiroDate)
                  }}<br />
                </template>
                <!-- <vx-tooltip text="Detail Mapping" class="mr-4"  >
                  <vs-button
                  v-on:click="showDetail(tr)"
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                  />
                </vx-tooltip> -->
              </span>
              <span v-if="type == 'payment'">
                Method : {{ tr.PaymentMethod }}<br />
                Ref Code : {{ tr.ReferenceCode }}<br />

                Payment Value : {{ priceFormat(tr.Amount) }}<br />
                <template v-if="tr.GiroBankID != 0">
                  Customer :
                  {{ "(" + tr.CustomerCode + ") " + tr.CustomerName }} <br />
                  Bank : {{ tr.BankName }} ({{ tr.BankAccountNumber }} -
                  {{ tr.BankAccountName }})<br />
                </template>
              </span>
              <span v-if="type == 'bank-statement'">
                Branch Code : {{ tr.BranchCode }} <br />
                Statement Code :{{ tr.BankStatement.Code }}<br />
                Payment Value : {{ priceFormat(tr.TransactionValue) }}<br />
                Name VA : {{ tr.NameVa }} <br />
                Reference Code : {{ tr.ReferenceCode ? tr.ReferenceCode : "-"
                }}<br />
                No Giro : {{ tr.NoGiro ? tr.NoGiro : "-" }}<br />
                Remark : {{ tr.Note }}<br />
                Txn Date : {{ dateFormat(tr.TxnDate) }}<br />
              </span>
              <span v-if="type == 'supplier-payment'">
                Code : {{ tr.Code }}<br />
                No Giro : {{ tr.GiroNumber ? tr.GiroNumber : "-" }}<br />
                Supplier : {{ "(" + tr.PartnerCode + ") " + tr.PartnerName }}
                <br />
                Bank : {{ tr.BankName }} ({{ tr.BankAccountNumber }} -
                {{ tr.BankAccountName }})<br />
                Payment Value : {{ priceFormat(tr.Amount) }}<br />
              </span>
            </div>
            <div slot="footer">
              <vs-row vs-justify="flex-end">
                <transition mode="out-in">
                  <vs-button
                    v-if="!listRecon.includes(type + tr.ID)"
                    type="gradient"
                    color="danger"
                    v-on:click="addReconcile(type, tr)"
                    >reconcile</vs-button
                  >
                  <vs-button
                    v-else
                    type="gradient"
                    color="success"
                    v-on:click="unReconcile(type, tr)"
                    >cancel reconcile</vs-button
                  >
                </transition>
              </vs-row>
            </div>
          </vs-card>
        </template>
      </card-data-table>
      <!-- <vs-button v-if="other.length < optionOther.length " color="primary" type="filled" v-on:click="loadMore" >Load More </vs-button> -->
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  created() {},
  data() {
    return {
      inputs: [
        {
          additional_value: "",
          cost_center: null,
          coa: null,
          territory: null,
        },
      ],
      additionalCoa: [
        {
          additional_value: 0,
          cost_center: null,
          coa: null,
          territory: null,
        },
      ],
      alloptionTerritory: [],
      optionTerritory: [],
      additionalValue: [],
      costCenter: [],
      coa: [],
      optionCostCenter: [],
      optionCoa: [],
      totalSelected: 0,
      listRecon: [],
      detailListRecon: [],
      responseData: {},
      detail: false,
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "1",
      },
      methods: ["", "Cash", "Giro", "Check", "Transfer"],
      suggestion: [],
      type: "cash-deposit",
      suggestID: [],
      other: [],
      optionOther: [],
      invoices: [],
      totalValue: 0,
      remainingValue: 0,
    };
  },
  props: {
    selected: Object,
    operatingUnitText: String,
  },
  methods: {
    // initialState() {
    //   return {
    //     inputs: [
    //       {
    //         additional_value: 0,
    //         cost_center: null,
    //         coa: null,
    //         territory: null,
    //       },
    //     ],
    //     additionalCoa: [
    //       {
    //         additional_value: 0,
    //         cost_center: null,
    //         coa: null,
    //         territory: null,
    //       },
    //     ],
    //     alloptionTerritory: [],
    //     optionTerritory: [],
    //     additionalValue: [],
    //     costCenter: [],
    //     coa: [],
    //     optionCostCenter: [],
    //     optionCoa: [],
    //     totalSelected: 0,
    //     listRecon: [],
    //     detailListRecon: [],
    //     responseData: {},
    //     detail: false,
    //     params: {
    //       search: "",
    //       length: 10,
    //       page: 1,
    //       order: "desc",
    //       sort: "1",
    //     },
    //     methods: ["", "Cash", "Giro", "Check", "Transfer"],
    //     suggestion: [],
    //     type: "cash-deposit",
    //     suggestID: [],
    //     other: [],
    //     optionOther: [],
    //     invoices: [],
    //     totalValue: 0,
    //     remainingValue: 0,
    //   };
    // },
    onChangeOU(val) {
      // if (this.alloptionTerritory.length == 0) {
      //   this.alloptionTerritory = this.optionTerritory;
      //   console.log("this.alloptionTerritory", this.alloptionTerritory);
      // }

      // console.log("Val: ", val);
      // if (this.optionTerritory.includes(val)) {
      //   console.log("this.optionTerritory", this.optionTerritory);
      //   this.optionTerritory.splice(this.optionTerritory.indexOf(val), 1);
      // }
      console.log(val);
    },
    addField() {
      this.inputs.push({
        additional_value: 0,
        cost_center: null,
        coa: null,
        territory: null,
      });
      this.additionalCoa.push({
        additional_value: 0,
        cost_center: null,
        coa: null,
        territory: null,
      });
      console.log(this.inputs);
    },
    removeField(index) {
      this.inputs.splice(index, 1);
    },
    async getoption() {
      try {
        await this.getOptionCostCenter();
        await this.getOptionCoa();
      } catch (e) {
        this.$vs.notify({
          title: "Error",
          text: e.message,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    async getOptionCoa(search = "") {
      this.$vs.loading();
      try {
        const resp = await this.$http.get("/api/v1/master/chart-of-account", {
          params: {
            order: "code",
            sort: "asc",
            length: 20,
            search,
          },
        });
        if (resp.code == 200) {
          var _this = this;
          resp.data.records.forEach(function (e) {
            _this.optionCoa.push(e.coa);
          });
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Coa option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
        throw e;
      }
    },
    onCoaSearch(search) {
      if (search.length < 3 && search.length > 0) {
        return;
      }
      this.getOptionCoa(search);
    },
    async getOptionCostCenter(search = "") {
      try {
        const resp = await this.$http.get("/api/v1/master/cost-center", {
          params: {
            order: "name",
            sort: "asc",
            length: 50,
            search,
          },
        });
        if (resp.code == 200) {
          this.optionCostCenter = resp.data.records;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Cost Center option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
        throw e;
      }
    },
    onCostCenterSearch(search) {
      if (search.length < 3 && search.length > 0) {
        return;
      }
      this.getOptionCostCenter(search);
    },
    queryCoa({ Code, Name }) {
      return `${Code} - ${Name}`;
    },

    showDetail(data) {
      this.invoices = data;
      this.detail = 1;
    },
    formatPrice(angka) {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    unReconcile(type, row) {
      if (type == "payment") {
        this.totalSelected -= row.Amount;
      } else if (type == "cash-deposit") {
        this.totalSelected -= row.DepositValue;
      } else if (type == "customer-deposit") {
        this.totalSelected -= row.DepositValue;
      } else if (type == "bank-statement") {
        this.totalSelected -= row.TransactionValue;
      } else if (this.type == "supplier-payment") {
        this.totalSelected -= row.Amount;
      }
      this.listRecon.splice(
        this.listRecon.indexOf(type + row.ID.toString()),
        1
      );
      this.detailListRecon.splice(
        this.listRecon.indexOf(type + row.ID.toString()),
        1
      );
      let remVal = 0
      if (this.selected.TransactionValue < 0) {
        remVal = parseFloat(this.selected.TransactionValue) + parseFloat(this.totalSelected)
      } else {
        remVal = parseFloat(this.selected.TransactionValue) - parseFloat(this.totalSelected)
      }

      this.remainingValue = this.priceFormat(
        remVal - parseFloat(this.totalValue)
      );
    },
    addReconcile(type, row) {
      let array = {
        type: type,
        id: row.ID,
        row: row,
      };
      if (type == "payment") {
        this.totalSelected += row.Amount;
      } else if (type == "cash-deposit") {
        this.totalSelected += row.DepositValue;
      } else if (type == "customer-deposit") {
        this.totalSelected += row.DepositValue;
      } else if (type == "bank-statement") {
        this.totalSelected += row.TransactionValue;
      } else if (this.type == "supplier-payment") {
        this.totalSelected += row.Amount;
      }

      if (this.listRecon.includes(type + row.ID.toString())) {
      } else {
        this.listRecon.push(type + row.ID.toString());
        this.detailListRecon.push(array);
        let remVal = 0
        if (this.selected.TransactionValue < 0) {
          remVal = parseFloat(this.selected.TransactionValue) + parseFloat(this.totalSelected)
        } else {
          remVal = parseFloat(this.selected.TransactionValue) - parseFloat(this.totalSelected)
        }

        this.remainingValue = this.priceFormat(
          remVal - parseFloat(this.totalValue)
        );
      }
    },
    getSuggestion() {
      const params = {
        search: this.selected.ReferenceCode,
      };
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.suggestion = r.data.cashBank;
          this.suggestID = [];
          this.suggestion.map((r) => {
            this.suggestID.push(r.ID);
          });
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    validateAdditionalCoa() {
      let result = true;
      console.log(this.inputs);
      for (let i = 0; i < this.inputs.length; i++) {
        const value = isNaN(parseFloat(this.inputs[i].additional_value))
          ? 0
          : parseFloat(this.inputs[i].additional_value);
        if (
          (!this.inputs[i].coa || Object.keys(this.inputs[i].coa) === 0) &&
          (!this.inputs[i].cost_center ||
            Object.keys(this.inputs[i].cost_center) === 0) &&
          (!this.inputs[i].territory ||
            Object.keys(this.inputs[i].territory) === 0) &&
          value === 0
        ) {
          result = true;
        } else {
          if (!this.inputs[i].coa || Object.keys(this.inputs[i].coa) === 0) {
            this.$vs.notify({
              color: "danger",
              title: "Reconcile",
              text: "Coa is required",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            result = false;
            // return result;
            break;
          }

          if (
            !this.inputs[i].cost_center ||
            Object.keys(this.inputs[i].cost_center) === 0
          ) {
            this.$vs.notify({
              color: "danger",
              title: "Reconcile",
              text: "Cost Center is required",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            result = false;
            // return result;
            break;
          }

          if (
            !this.inputs[i].territory ||
            Object.keys(this.inputs[i].territory) === 0
          ) {
            this.$vs.notify({
              color: "danger",
              title: "Reconcile",
              text: "Territory is required",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            result = false;
            // return result;
            break;
          }

          if (value === 0) {
            this.$vs.notify({
              color: "danger",
              title: "Reconcile",
              text: "Additional Value must be greater than 0",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            result = false;
            // return result;
            break;
          }
        }
      }
      // this.inputs.forEach((v) => {
      //   const value = isNaN(parseFloat(v.additional_value))
      //     ? 0
      //     : parseFloat(v.additional_value);
      //   if (!v.coa && !v.cost_center && !v.territory && value === 0) {
      //     result = true;
      //   } else {
      //     if (!v.coa) {
      //       this.$vs.notify({
      //         color: "danger",
      //         title: "Reconcile",
      //         text: "Coa is required",
      //         position: "top-right",
      //         iconPack: "feather",
      //         icon: "icon-x-circle",
      //       });
      //       result = false;
      //       return result;
      //     }

      //     if (!v.cost_center) {
      //       this.$vs.notify({
      //         color: "danger",
      //         title: "Reconcile",
      //         text: "Cost Center is required",
      //         position: "top-right",
      //         iconPack: "feather",
      //         icon: "icon-x-circle",
      //       });
      //       result = false;
      //       return result;
      //     }

      //     if (!v.territory) {
      //       this.$vs.notify({
      //         color: "danger",
      //         title: "Reconcile",
      //         text: "Territory is required",
      //         position: "top-right",
      //         iconPack: "feather",
      //         icon: "icon-x-circle",
      //       });
      //       result = false;
      //       return result;
      //     }

      //     if (value === 0) {
      //       this.$vs.notify({
      //         color: "danger",
      //         title: "Reconcile",
      //         text: "Additional Value must be greater than 0",
      //         position: "top-right",
      //         iconPack: "feather",
      //         icon: "icon-x-circle",
      //       });
      //       result = false;
      //       return result;
      //     }
      //   }
      // });
      return result;
    },
    handleReconcile() {
      const remain = parseFloat(this.remainingValue.replace(/,/g, ""));
      console.log("remain", remain);

      if (!this.validateAdditionalCoa()) {
        return;
      }

      if (parseFloat(this.remainingValue) !== 0) {
        this.$vs.notify({
          color: "danger",
          title: "Reconcile",
          text: "Remaining Value must be 0",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      this.$vs.loading();
      let params = {};
      let recon = [];
      this.detailListRecon.map((v) => {
        let payment_id = 0;
        let payment_code = "";
        let cash_bank_id = 0;
        let cash_bank_code = "";
        let bank_statement_id = 0;
        let bank_statement_code = "";
        let supplier_payment_id = 0;
        let supplier_payment_code = "";
        if (v.type == "cash-deposit") {
          cash_bank_id = v.row.ID;
          cash_bank_code = v.row.Code;
        } else if (v.type == "customer-deposit") {
          cash_bank_id = v.row.ID;
          cash_bank_code = v.row.Code;
        } else if (v.type == "payment") {
          payment_id = v.row.ID;
          payment_code = v.row.Code;
        } else if (v.type == "supplier-payment") {
          supplier_payment_id = v.row.ID;
          supplier_payment_code = v.row.Code;
        } else {
          bank_statement_id = v.row.ID;
          bank_statement_code = v.row.Code;
        }
        const array = {
          payment_id: payment_id,
          payment_code: payment_code,
          cash_bank_id: cash_bank_id,
          cash_bank_code: cash_bank_code,
          bank_statement_id: bank_statement_id,
          bank_statement_code: bank_statement_code,
          supplier_payment_id: supplier_payment_id,
          supplier_payment_code: supplier_payment_code,
        };
        recon.push(array);
      });
      params = {
        reconcile_json: recon,
        bank_statement_id: this.selected.ID,
        // additional_coa,
      };

      if (this.inputs.length > 0 && this.inputs[0].coa) {
        const additional_coa = this.inputs.map((v) => {
          console.log("coa data", v)
          return {
            coa_id: v.coa.ID,
            cost_center_id: v.cost_center.ID,
            territory_id: v.territory.TerritoryID,
            additional_value: parseFloat(v.additional_value.replace(/,/g, "")),
          };
        });
        params.additional_coa = additional_coa;
      }

      console.log(params);
      this.$http
        .post("/api/v1/bank-statement/reconcile-manual", params)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();

            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log(e);
        });
    },
    loadMore() {
      // this.$vs.loading();
      this.optionOther.map((r, i) => {
        if (
          i >= this.other.length &&
          i <= parseInt(this.other.length) + parseInt(10)
        ) {
          if (!this.suggestID.includes(r.ID)) {
            this.other.push(r);
          }
        }
      });
    },
    getOther() {
      const params = {
        search: "",
      };
      console.log(params);
      this.$vs.loading();
      this.other = [];
      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.optionOther = r.data.cashBank;
          this.optionOther.map((r, i) => {
            if (i < 2) {
              if (!this.suggestID.includes(r.ID)) {
                this.other.push(r);
              }
            }
          });
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();
      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          if (this.type == "cash-deposit") {
            this.optionOther = r.data.cashBank;
            this.responseData.length = r.data.cashBank.length;
          } else if (this.type == "customer-deposit") {
            this.optionOther = r.data.cashBank;
            this.responseData.length = r.data.cashBank.length;
          } else if (this.type == "payment") {
            this.optionOther = r.data.payment;
            this.responseData.length = r.data.payment.length;
          } else if (this.type == "bank-statement") {
            this.optionOther = r.data.bankStatementLines;
            this.responseData.length = r.data.length;
          } else if (this.type == "supplier-payment") {
            this.optionOther = r.data.otherPayment;
            this.responseData.length = r.data.length;
          }
          this.responseData = r.data;

          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        let path = "";
        let status = "";
        let sort = "id";
        let order = "desc";
        let type = 1;
        let is_reconcile_bank_statement = true;
        let method = "cash";
        if (this.type == "cash-deposit") {
          path = "/cash-bank/data-table";
          status = 3;
          (order = "cash_bank_managements.id"), (sort = "desc");
          type = 1;
          is_reconcile_bank_statement = true;
        } else if (this.type == "customer-deposit") {
          path = "/cash-bank/data-table";
          status = 3;
          type = 2;
          is_reconcile_bank_statement = false;
          (order = "id"), (sort = "desc");
        } else if (this.type == "payment") {
          path = "/payment/data-table";
          status = 2;
          (sort = "id"), (order = "desc");
          is_reconcile_bank_statement = true;
        } else if (this.type == "bank-statement") {
          path = "/bank-statement/by-bank/data-table";
          status = 100;
          (sort = "desc"), (order = "id");
          type = 1;
        } else if (this.type == "supplier-payment") {
          path = "/other-payment/for-dn/data-table";
          status = 3;
          (order = "desc"), (sort = "id");
        }
        this.$http
          .get("/api/v1" + path, {
            params: {
              type,
              status: status,
              search: params.search,
              length: 10,
              page: params.page,
              order: order,
              sort: sort,
              // territory_id: this.selected.TerritoryID,
              territory_text: this.operatingUnitText,
              is_reconcile_bank_statement,
              bank_statement_id: this.selected.ID,
              bank_id: this.selected.BankStatement.BankID,
              account_number: this.selected.BankStatement.AccountNumber,
              transaction_value: Math.abs(this.selected.TransactionValue),
              method: this.type === "cash-deposit" ? method : undefined,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    calculateAdditionaTotalValue() {
      console.log("calculate");

      let total = 0;
      this.inputs.forEach((v) => {
        const value = v.additional_value;
        total += value ? parseFloat(value.replace(/,/g, "")) : 0;
      });
      this.totalValue = total;
      console.log("selected : ", this.totalValue);

      let remVal = 0
      if (this.selected.TransactionValue < 0) {
        remVal = parseFloat(this.selected.TransactionValue) + parseFloat(this.totalSelected)
      } else {
        remVal = parseFloat(this.selected.TransactionValue) - parseFloat(this.totalSelected)
      }

      this.remainingValue = this.priceFormat(
        remVal - parseFloat(this.totalValue)
      );
      // this.totalSelected = total;
    },
    onFinishIput(index) {
      this.inputs[index].additional_value = this.formatPrice(
        this.inputs[index].additional_value
      );
    },
    filterTerritory() {
      // TerritoryAreaID
      const territories = [
        ...this.$store.state.operatingUnit.filter.territory_single,
      ];
      console.log("territories", territories);
      console.log("listRecon", this.detailListRecon);

      const temp = territories.filter((v) =>
        this.detailListRecon.some((r) => r.row.TerritoryID === v.TerritoryID)
      );

      const seen = new Set();
      const arr = [];
      temp.forEach((item) => {
        if (seen.has(item.TerritoryName)) {
          return;
        }
        seen.add(item.TerritoryName);
        arr.push(item);
      });
      this.optionTerritory = arr;
      //this.optionTerritory = temp.filter((item) => {
      //   const duplicate = seen.has(item.TerritoryName);
      //   seen.add(item);
      //   return !duplicate;
      // });
    },
    // formatPrice(angka, prefix = "") {
    //   return angka
    //     .toString()
    //     .replace(/,/g, "")
    //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // },
  },

  mounted() {
    console.log(this.selected);
    console.log("mounted");
    if (this.selected.ReferenceCode != "") {
      this.getSuggestion();
    }
    if (this.selected.TransactionValue < 0) {
      this.type = "bank-statement";
      this.reloadData(this.params);
    } else if (this.selected.TransactionValue > 0) {
      this.type = "cash-deposit";
      this.reloadData(this.params);
    }
    // this.getOther()

    // this.getOptionCoa();
    // this.getOptionCostCenter();
    this.getoption();
    // this.optionTerritory = this.$store.state.operatingUnit.filter.territory_single;
    console.log(this.inputs);
  },
  computed: {},
  watch: {
    "create.method"() {},
    type() {
      this.$nextTick(() => {
        this.reloadData(this.params);
      });
      // this.reloadData(this.params);
    },
    operatingUnitText() {
      console.log(this.operatingUnitText);
      console.log("unit text");
    },
    "selected.ID"() {
      this.$nextTick(() => {
        // if (this.selected.ReferenceCode != "") {
        //   this.getSuggestion()
        // }
        if (this.selected.TransactionValue < 0) {
          this.type = "bank-statement";
          console.log("bank-statement");
        } else {
          this.type = "cash-deposit";
          this.reloadData(this.params);
        }

        console.log(this.selected);
      });
    },
    "listRecon.length"() {
      this.$nextTick(() => {
        this.calculateAdditionaTotalValue();
        this.filterTerritory();
      });
    },
    "inputs.length"() {
      this.$nextTick(() => {
        this.calculateAdditionaTotalValue();
      });
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>
