<template>
  <div class="vx-row mb-12">
    <div
      v-bind:class="[
        detail ? detailShow + ' vertical-divider' : '',
        detailHide,
      ]"
    >
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
              <span>Payment Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
              <div class="mt-4 flex justify-between">
                <date-range-picker
                  style="min-height: 40px"
                  class="w-full"
                  ref="picker"
                  opens="center"
                  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="false"
                  :showDropdowns="false"
                  :autoApply="true"
                  v-model="date"
                  :linkedCalendars="true"
                >
                  <template v-slot:input="picker">
                    {{ globalDateFormat(picker.startDate) }} -
                    {{ globalDateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
                <vs-button
                  class="ml-4"
                  color="danger"
                  icon-pack="feather"
                  icon="icon-x"
                  @click="
                    () => {
                      this.date = { startDate: null, endDate: null };
                    }
                  "
                >
                </vs-button>
                <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
              <span>Posting Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
              <div class="mt-4 flex justify-between">
                <date-range-picker
                  style="min-height: 40px"
                  class="w-full"
                  ref="picker"
                  opens="center"
                  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="false"
                  :showDropdowns="false"
                  :autoApply="true"
                  v-model="postingdate"
                  :linkedCalendars="true"
                >
                  <template v-slot:input="picker">
                    {{ globalDateFormat(picker.startDate) }} -
                    {{ globalDateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
                <vs-button
                  class="ml-4"
                  color="danger"
                  icon-pack="feather"
                  icon="icon-x"
                  @click="
                    () => {
                      this.postingdate = { startDate: null, endDate: null };
                    }
                  "
                >
                </vs-button>
                <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="vx-row mb-12"> -->
      <div class="vx-col w-full">
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Operating Unit Bank </span>
            <div class="vx-col sm:w-1/3 w-full"></div>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="operatingUnitBank"
              :options="optionOperatingUnitBank"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="160"
              :limit="4"
              placeholder=" Type to search"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">
                    {{ dt.option.BankName }}
                    <br />
                    {{ dt.option.AccountName }}
                    <br />
                    {{ dt.option.AccountNumber }}
                  </span>
                </span>
              </template>

              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">
                    {{ dt.option.BankName }}
                    <br />
                    {{ dt.option.AccountName }}
                    <br />
                    {{ dt.option.AccountNumber }}
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>

      <div class="vx-col w-full">
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Operating Unit</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="operatingUnit"
              :options="optionOU"
              :multiple="true"
              :allow-empty="true"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              track-by="ID"
              label="Name"
              @input="selectedOperatingUnit"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >({{ props.option.Code }}), {{ props.option.Name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">
                    <span class="option__title"
                      >({{ props.option.Code }}), {{ props.option.Name }}</span
                    >
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="vx-col w-full">
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="territory"
              :options="optionTerritory"
              :multiple="true"
              :allow-empty="true"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              track-by="ID"
              label="Name"
              @input="selectedTerritory"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >({{ props.option.Code }}), {{ props.option.Name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">
                    <span class="option__title"
                      >({{ props.option.Code }}), {{ props.option.Name }}</span
                    >
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="vx-col w-full mb-6 mt-3">
        <vs-radio v-model="type" vs-value="payment" class="mr-4" vs-name="type"
          >Payment</vs-radio
        >
        <vs-radio
          v-model="type"
          vs-value="cash-deposit"
          class="mr-4"
          vs-name="type"
        >
          Cash Deposit
        </vs-radio>
        <vs-radio
          v-model="type"
          vs-value="customer-deposit"
          class="mr-4"
          vs-name="type"
          >Customer Deposit</vs-radio
        >
        <vs-radio
          v-model="type"
          vs-value="supplier-deposit"
          class="mr-4"
          vs-name="type"
        >
          Supplier Deposit
        </vs-radio>
      </div>
      <div class="vx-col w-full">
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-button
              class="ml-4 mt-2"
              color="primary"
              icon-pack="feather"
              icon="icon-printer"
              v-on:click="downloadExcel"
              >Download Excel</vs-button
            >
          </div>
        </div>
      </div>

      <vs-tabs>
        <vs-tab label="Data">
          <div class="tab-text">
            <tableData
              :type="this.type"
              :date="this.date"
              :postingDate="this.postingdate"
              :operatingUnitBank="this.operatingUnitBank"
              :operatingUnitText="this.operatingUnitText"
            />
          </div>
        </vs-tab>
        <vs-tab label="Export">
          <div class="tab-text">
            <tableExport />
          </div>
        </vs-tab>
      </vs-tabs>

      <!-- IF PAYMENT -->
      <!-- <div v-if="type == 'payment'">
                <data-table :responseData="responseData" :propsParams="params" :header="header"
                    @reloadDataFromChild="reloadData">
                    <template slot="tbody">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.payment"
                            v-bind:class="[selectedData.ID == tr.ID ? 'colored' : '', '']">

                            <vs-td> {{ tr.CustomerCode }} {{ tr.CustomerName }} </vs-td>
                            <vs-td>
                                Payment Code : {{ tr.Code }}
                                <br>
                                Reference Code : {{ tr.ReferenceCode }}
                                <br>
                                Method : {{ tr.PaymentMethod }}
                                <br>
                                Bank : {{ tr.BankName }} ({{ tr.BankAccountNumber }} - {{ tr.BankAccountName }})
                                <br>
                            </vs-td>
                            <vs-td>
                                <template v-for="(r) in tr.InvoiceCode.split(',')">
                                    <div v-bind:key="r">
                                        {{ r }}
                                        <br>
                                    </div>
                                </template>
                            </vs-td>
                            <vs-td>
                                {{ priceFormat(tr.Amount) }}
                            </vs-td>
                            <vs-td>
                                Created Date : {{ dateFormat(tr.CreatedAt) }}
                                <br>
                                Payment Date : {{ dateFormat(tr.DatePayment) }}
                                <br>
                                Posting Date : {{ dateFormat(tr.PostingDate) }}
                            </vs-td>
                        </vs-tr>
                    </template>
                </data-table>
            </div> -->

      <!-- IF CASH DEPOSIT -->
      <!-- <div v-else-if="type == 'cash-deposit'">
                <data-table :responseData="responseData" :propsParams="params" :header="headers"
                    @reloadDataFromChild="reloadData">
                    <template slot="tbody">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.cashBank"
                            v-bind:class="[selectedData.ID == tr.ID ? 'colored' : '', '']"> -->

      <!-- <vs-td> {{ tr.PersonCode }} {{ tr.PersonName }} </vs-td> -->
      <!-- <vs-td>
                                Cash Deposit Code : {{ tr.Code }}
                                <br>
                                Reference Code : {{ tr.ReferenceCode }}
                                <br>
                                Method : {{ methods[tr.Method] }}
                                <br>
                                Bank : {{ tr.BankName }} ({{ tr.AccountNumber }} - {{ tr.AccountName }})
                                <br>
                            </vs-td>
                            <vs-td> {{ priceFormat(tr.DepositValue) }}</vs-td> -->
      <!-- <vs-td>
              <template v-for="(r) in tr.InvoiceCode.split(',')">
                <div v-bind:key="r">
                  {{ r }}
                  <br>
                </div>
              </template>
            </vs-td>
            <vs-td>
              {{ priceFormat(tr.Amount) }}
            </vs-td> -->
      <!-- <vs-td>
                                Created Date : {{ dateFormat(tr.CreatedAt) }}
                                <br>
                                Payment Date : {{ dateFormat(tr.DatePayment) }}
                                <br>
                                Posting Date : {{ dateFormat(tr.DatePosting) }}
                            </vs-td>
                        </vs-tr>
                    </template>
                </data-table>
            </div> -->
    </div>
    <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? 'vx-col md:w-5/6 w-full  mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <vs-col
            vs-offset="0"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
          >
            <vs-button
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            ></vs-button>
          </vs-col>
          <detail-div @close="handleClose" :selected="selectedData" />
        </vs-row>
      </div>
    </transition>
  </div>
</template>
<script>
import moment from "moment";
import detail from "./detail.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Datepicker from "vuejs-datepicker";
import tableData from "./table_data.vue";
import tableExport from "./table_export.vue";
// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
  components: {
    "detail-div": detail,
    DateRangePicker,
    Datepicker,
    tableData,
    tableExport,
  },
  props: {
    selected: Object,
    option: Object,
    operatingUnitText: String,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      date: {
        startDate: null,
        endDate: null,
        // startPostingDate: null,
        // endPostingDate: null,
      },
      postingdate: {
        startDate: null,
        endDate: null,
      },

      header: [
        {
          text: "Customer",
          value: "customer_code",
          // width: '5%'
        },
        {
          text: "Payment",
          value: "reference_code",
        },
        {
          text: "Invoice",
          sortable: false,
        },
        {
          text: "Amount",
          sortable: false,
        },
        {
          text: "Date",
          value: "date_payment",
        },
      ],

      headers: [
        // {
        //   text: "Customer",
        //   value: "customer_code",
        //   // width: '5%'
        // },
        {
          text: "Payment",
          value: "reference_code",
        },
        {
          text: "Deposit Value",
          value: "deposit_value",
        },
        {
          text: "Date",
          value: "date_payment",
        },
      ],
      // responseData: {},
      methods: ["", "Cash", "Giro", "Cheque", "Transfer"],
      responseData: {},
      detailShow: "vx-col md:w-1/6 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      notes: "",
      approveOrReject: false,
      approvalPrompt: false,
      rejectPrompt: false,
      selectedData: {},
      optionOU: [],
      operatingUnit: [],
      territory: [],
      // operatingUnitText:'',
      bankStatement: {},
      operatingUnitBank: {},
      // BankStatement: {},
      optionTerritory: [],
      optionOperatingUnitBank: [],
      OUTerritory: [],
      bank: {},
      optionBank: [],
      PaymentMethod: {},
      PaymentMethodCash: {},
      PaymentMethodTrf: {},
      typePayment: false,
      suggestion: [],
      // type:'cash-deposit',
      type: "payment",
      suggestID: [],
      other: [],
      optionOther: [],
      invoices: [],
    };
  },
  computed: {},
  watch: {
    "create.method"() {},
    "selected.ID"() {
      this.$nextTick(() => {
        // if (this.selected.ReferenceCode != "") {
        //   this.getSuggestion()
        // }
        // if (this.selected.TransactionValue < 0) {
        //   this.type = 'bank-statement'
        //   console.log('bank-statement')
        // } else {
        //   this.type = 'cash-deposit'
        //   this.reloadData(this.params)
        // }
        // if (this.selected.type == 'payment'){
        //     this.type = 'payment'
        //     this.reloadData(this.params)
        // } else {
        //     this.type = 'cash-deposit'
        //     this.reloadData(this.params)

        // }

        console.log(this.selected);
      });
    },
    // operatingUnit() {
    //     console.log(this.operatingUnit)
    //     console.log("this.operatingUnitText", this.operatingUnitText);
    //     if (this.operatingUnit.length == 0) {
    //         this.operatingUnit = { ID: 0, Code: "All", Name: "All" };
    //         this.operatingUnitText = "";
    //     } else {
    //         if (this.operatingUnit[this.operatingUnit.length - 1].ID == 0) {
    //             this.operatingUnit = { ID: 0, Code: "All", Name: "All" };
    //             this.operatingUnitText = "";
    //         } else {
    //             const operatingUnit = this.operatingUnit.filter((v) => {
    //                 return v.ID != 0;
    //             });
    //             if (this.operatingUnit.length > operatingUnit.length) {
    //                 this.operatingUnit = operatingUnit;
    //             }
    //             console.log(operatingUnit);
    //             const territory = [];
    //             this.operatingUnit.map((v) => {
    //                 //disini
    //                 territory.push(v.TerritoryID);
    //                 console.log(v, "v selected")
    //             });
    //             this.operatingUnitText = territory.join(",");
    //             console.log("this.operatingUnitText 2 ", this.operatingUnitText);
    //         }
    //     }
    //     this.params.page = 1;
    //     // this.getBankForm(this.operatingUnit)
    //     this.reloadData(this.params);
    //     this.reloadDataReconciled(this.params);
    //     // const checkAll = this.operatingUnit.filter(v => {
    //     //   return v.ID == 0
    //     // })

    //     //   if (this.operatingUnit.length == 0) {
    //     //     this.operatingUnit = {ID:0,Code:'All',Name:'All'}
    //     //    this.operatingUnitText = ''
    //     //   } else {
    //     //     if (this.operatingUnit[this.operatingUnit.length-1].ID == 0) {
    //     //     this.operatingUnit = {ID:0,Code:'All',Name:'All'}
    //     //     this.operatingUnitText = ''
    //     //   } else {
    //     //     const operatingUnit = this.operatingUnit.filter(v => {
    //     //       return v.ID != 0
    //     //     })
    //     //     if (this.operatingUnit.length > operatingUnit.length) {
    //     //       this.operatingUnit =  operatingUnit
    //     //     }
    //     //     console.log(operatingUnit)
    //     //     const territory = []
    //     //     this.operatingUnit.map(v => {
    //     //       territory.push(v.OperatingUnitTerritory.TerritoryID)
    //     //     })
    //     //     this.operatingUnitText = territory.join(",")
    //     //   }
    //     // }
    //     // // this.getBankForm(this.operatingUnit)
    //     // console.log("line 435",this.params);
    //     // this.reloadData(this.params)
    //     // // this.reloadDataReconciled(this.params);
    //     // // this.handleClose()
    // },
  },

  mounted() {
    this.getBankForm();
    // this.reloadData(this.params);

    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
    console.log(this.selected);
    console.log("mounted");
    // if (this.selected.ReferenceCode != "") {
    //   this.getSuggestion();
    // }
    // this.getOther()
  },
  methods: {
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    handleApprove(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 1;
      selected.class = "highlight";
      this.selectedData = selected;
      this.rejectPrompt = false;
      this.approvalPrompt = true;
      console.log(this.selectedData);
    },
    handleReject(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 0;
      selected.class = "highlight";
      this.selectedData = selected;
      this.approvalPrompt = false;
      this.rejectPrompt = true;
    },
    closeDetail() {
      this.activePrompt2 = false;
      this.detail = false;
      this.selectedData.class = "";
      this.selectedData = {
        ID: 0,
      };
      this.reloadData(this.params);
    },
    getSuggestion() {
      const params = {
        search: this.selected.ReferenceCode,
      };
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.suggestion = r.data.cashBank;
          this.suggestID = [];
          this.suggestion.map((r) => {
            this.suggestID.push(r.ID);
          });
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },

    downloadExcel() {
      const params = this.params;
      console.log(this.type, "type");
      let path = "";
      let status = "";
      let sort = "id";
      let order = "desc";
      let type = 1;
      let is_reconcile_bank_statement = true;
      if (this.type == "payment") {
        path = "/bank-statement/by-bank/export/waiting/data-table/export";
        status = 2;
        (sort = "id"), (order = "desc");
        type = 1;
      } else if (this.type == "cash-deposit") {
        path =
          "/bank-statement/by-bank/export-cash-deposit/waiting/data-table/export";
        status = 3;
        type = 1;
        (order = "id"), (sort = "desc");
      } else if (this.type == "customer-deposit") {
        path =
          "/bank-statement/by-bank/export-cash-deposit/waiting/data-table/export";
        status = 3;
        (order = "id"), (sort = "desc");
        type = 2;
        is_reconcile_bank_statement = false;
      } else if (this.type == "supplier-deposit") {
        path =
          "/bank-statement/by-bank/export-cash-deposit/waiting/data-table/export";
        status = 3;
        (order = "id"), (sort = "desc");
        type = 3;
        is_reconcile_bank_statement = false;
      }

      const dataTable = {
        type,
        status: status,
        search: params.search,
        length: 10,
        page: params.page,
        order: order,
        sort: sort,
        is_reconcile_bank_statement,
        bank_id: this.operatingUnitBank.BankID,
        account_number: this.operatingUnitBank.AccountNumber,
        territory_text: this.operatingUnitText,
        start_collect_date: this.date.startDate
          ? moment(this.date.startDate).format("YYYY-MM-DD")
          : null,
        end_collect_date: this.date.endDate
          ? moment(this.date.endDate).format("YYYY-MM-DD")
          : null,
        start_collect_posting_date: this.postingdate.startDate
          ? moment(this.postingdate.startDate).format("YYYY-MM-DD")
          : null,
        end_collect_posting_date: this.postingdate.endDate
          ? moment(this.postingdate.endDate).format("YYYY-MM-DD")
          : null,
      };

      this.$vs.loading();
      this.$http
        .post("/api/v1" + path, dataTable, {
          //   responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          console.log(resp);
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            // var fileURL = window.URL.createObjectURL(new Blob([resp]));
            // var fileLink = document.createElement("a");
            // fileLink.href = fileURL;

            // fileLink.setAttribute("download", fileTitle + ".xlsx");

            // document.body.appendChild(fileLink);
            // fileLink.click();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    },

    dataTable(params) {
      // console.log(this.operatingUnitBank.AccountNumber, 'this.operatingUnitBank.AccountNumber')
      // console.log(this.operatingUnitBank, "dttb");
      console.log("type", this.type);
      return new Promise((resolve, reject) => {
        let path = "";
        let status = "";
        let sort = "id";
        let order = "desc";
        let type = 1;
        if (this.type == "payment") {
          path = "/payment/data-table";
          status = 2;
          (sort = "id"), (order = "desc");
          type = 1;
        } else if (this.type == "cash-deposit") {
          path = "/cash-bank/data-table";
          status = 3;
          type = 1;
          (order = "id"), (sort = "desc");
        } else if (this.type == "customer-deposit") {
          path = "/cash-bank/data-table/customer-deposit";
          status = 3;
          (order = "id"), (sort = "desc");
          type = 2;
        } else if (this.type == "supplier-deposit") {
          path = "/cash-bank/data-table/supplier-deposit";
          status = 3;
          (order = "id"), (sort = "desc");
          type = 3;
        }
        // this.$http.get('/api/v1/payment/data-table', {
        //   params: {
        this.$http
          .get("/api/v1" + path, {
            params: {
              type,
              status: status,
              search: params.search,
              // length: 10,
              length: params.length,
              page: params.page,
              order: order,
              sort: sort,

              is_reconcile_bank_statement: true,
              bank_id: this.operatingUnitBank.BankID,
              account_number: this.operatingUnitBank.AccountNumber,
              territory_text: this.operatingUnitText,
              start_collect_date: this.date.startDate
                ? moment(this.date.startDate).format("YYYY-MM-DD")
                : null,
              end_collect_date: this.date.endDate
                ? moment(this.date.endDate).format("YYYY-MM-DD")
                : null,
              start_collect_posting_date: this.postingdate.startDate
                ? moment(this.postingdate.startDate).format("YYYY-MM-DD")
                : null,
              end_collect_posting_date: this.postingdate.endDate
                ? moment(this.postingdate.endDate).format("YYYY-MM-DD")
                : null,

              // transaction_value: Math.abs(this.selected.TransactionValue),

              // console.log("ini tr", tr);
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getBankForm() {
      // this.$http.get('/api/v1/cash-bank/form-bank/' + this.operatingUnit.OperatingUnitTerritory.TerritoryID).then((r) => {
      this.$http
        .get("/api/v1/cash-bank/form-bank/0")
        .then((r) => {
          // this.optionTerritory = r.data.territory
          // this.optionTerritory = r.data.territories
          // this.optionBank = r.data.bank
          // console.log(r)
          this.getOptionOU();
          this.optionOperatingUnitBank = r.data.operatingUnitBank;
          this.operatingUnitBank = r.data.operatingUnitBank[0];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    findBankStatement(id) {
      this.$http
        .get("/api/v1/bank-statement/data-table/" + id)
        .then((r) => {
          if (r.code == 200) {
            this.bankStatement = r.data.bankStatement;
            console.log(r.data.bankStatement);
          } else {
            this.$vs.notify({
              color: "danger",
              title: r.message,
              text: "error catch",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleBack();
          }
        })
        .catch((e) => {
          this.$vs.notify({
            color: "danger",
            title: "error catch",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          this.handleBack();
        });
    },
    handleBack() {
      this.$router.push("/finance/bank-statement");
    },
    selectedOperatingUnit() {
      console.log("this.operatingUnitText", this.operatingUnitText);
      if (this.operatingUnit.length == 0) {
        // this.operatingUnit = { ID: 0, Code: "All", Name: "All" };
        // this.territory = { ID: 0, Code: "All", Name: "All" };
        this.operatingUnitText = "";
        this.setOption();
      } else {
        console.log(this.operatingUnit[this.operatingUnit.length - 1].ID);
        console.log(this.operatingUnit);
        if (this.operatingUnit[this.operatingUnit.length - 1].ID == 0) {
          // this.operatingUnit = { ID: 0, Code: "All", Name: "All" };
          // this.territory = { ID: 0, Code: "All", Name: "All" }];
          this.operatingUnitText = "";
          this.setOption();
        } else {
          const operatingUnit = this.operatingUnit.filter((v) => {
            return v.ID != 0;
          });
          if (this.operatingUnit.length > operatingUnit.length) {
            this.operatingUnit = operatingUnit;
          }
          console.log(operatingUnit);
          const ou = [];
          this.operatingUnit.map((v) => {
            //disini
            ou.push(v.ID);
          });
          this.setOption(false, ou);
          // this.operatingUnitText = ou.join(",");
        }
      }
      // this.params.page = 1;
      // this.getBankForm(this.operatingUnit)
      this.reloadData(this.params);
      this.reloadDataReconciled(this.params);
    },
    selectedTerritory() {
      if (this.territory.length == 0) {
        this.territory = [{ ID: 0, Code: "All", Name: "All" }];
        // this.operatingUnitText = "";
      } else {
        if (this.territory[this.territory.length - 1].ID == 0) {
          this.territory = [{ ID: 0, Code: "All", Name: "All" }];
          // this.operatingUnitText = "";
        } else {
          const territory = this.territory.filter((v) => {
            return v.ID != 0;
          });
          if (this.territory.length > territory.length) {
            this.territory = territory;
          }
        }
      }
      this.setOperatingUnitText();
      this.reloadData(this.params);
      this.reloadDataReconciled(this.params);
    },
    setOperatingUnitText() {
      let territory = this.territory.filter((v) => {
        return v.ID != 0;
      });

      // let territory = []
      let ou = [];
      if (territory.length > 0) {
        territory.map((v) => {
          //disini
          ou.push(v.ID);
        });
      } else {
        territory = this.optionTerritory.filter((v) => {
          return v.ID != 0;
        });
        territory.map((v) => {
          //disini
          ou.push(v.ID);
        });
        // } else {
        //     this.operatingUnitText = ou.join(",");
      }
      this.operatingUnitText = ou.join(",");
    },
    setOption(ouReplace = true, ouID = []) {
      // console.log(ouID);

      if (ouReplace) {
        this.optionOU = [];
        this.optionOU.push({ ID: 0, Code: "All", Name: "All" });
      }
      this.optionTerritory = [];
      this.optionTerritory.push({ ID: 0, Code: "All", Name: "All" });
      let hasID = [];
      this.OUTerritory.map((v) => {
        if (ouReplace) {
          if (!hasID.includes(v.ID)) {
            this.optionOU.push({ ID: v.ID, Code: v.Code, Name: v.Name });
          }
        }
        hasID.push(v.ID);
        console.log(ouID, v.ID, ouID.includes(v.ID));
        if (ouID.length == 0 || ouID.includes(v.ID)) {
          this.optionTerritory.push({
            OuID: v.ID,
            OuName: v.Name,
            ID: v.TerritoryID,
            Code: v.TerritoryCode,
            Name: v.TerritoryName,
          });
        }
      });
      if (ouReplace) {
        this.operatingUnit = [];
        this.operatingUnit.push({ ID: 0, Code: "All", Name: "All" });
      }
      this.territory = [];
      this.territory.push({ ID: 0, Code: "All", Name: "All" });
      this.setOperatingUnitText();
    },
    getOptionOU() {
      this.$http
        .get("/api/v1/master/operating-unit-with-territory")
        .then((resp) => {
          // this.$http.get("/api/v1/master/operating-unit").then((resp) => {
          // this.optionOU = [...{ID:0,Code:'All',Name:'All'},resp.data.records];

          this.OUTerritory = resp.data.records;
          this.setOption();
        });
    },

    handleShow(selected) {
      this.$router.push("/finance/bank-statement/" + selected.ID);
      // this.selectedData.class = ""
      // selected.class =  "highlight"
      // this.selectedData = selected
      // this.detail = true
    },
    handleClose() {
      this.reloadData(this.params);
      this.selectedData.class = "";
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
    },
    showDetail(data) {
      this.invoices = data;
      this.detail = 1;
    },

    formatPrice(angka, prefix = "") {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    loadMore() {
      // this.$vs.loading();
      this.optionOther.map((r, i) => {
        if (
          i >= this.other.length &&
          i <= parseInt(this.other.length) + parseInt(10)
        ) {
          if (!this.suggestID.includes(r.ID)) {
            this.other.push(r);
          }
        }
      });
    },

    getOther() {
      const params = {
        search: "",
      };
      console.log(params);
      this.$vs.loading();
      this.other = [];
      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.optionOther = r.data.cashBank;
          this.optionOther.map((r, i) => {
            if (i < 2) {
              if (!this.suggestID.includes(r.ID)) {
                this.other.push(r);
              }
            }
          });
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },

    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          if (this.type == "cash-deposit") {
            this.optionOther = r.data.cashBank;
            this.responseData.length = r.data.cashBank.length;
          } else if (this.type == "payment") {
            this.optionOther = r.data.payment;
            this.responseData.length = r.data.payment.length;
          }
          this.responseData = r.data;
          console.log(this.responseData);
          // this.data = r.data.bankStatement;
          // this.responseData = r.data;
          // this.responseData.length = r.data.bankStatement.length;
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}

.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}

.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}

.highlight > td {
  background-color: #d0cdf0;
}

.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}

.vs-dialog {
  max-width: 1000px;
}
</style>
