<template>
  <div class="flex flex-col gap-4" v-if="reconcileRecommendation.length > 0">
    <div
      class="flex flex-col gap-1"
      v-for="(recommendation, i) in reconcileRecommendation"
      :key="i"
    >
      <p>Type : {{ recommendation.name }}</p>
      <p>Code : {{ recommendation.code }}</p>
      <p>Value : {{ priceFormat(recommendation.amount) }}</p>
      <p>
        <span>{{
          recommendation.name === "Cash Deposit"
            ? "Deposit Slip No : "
            : "Reference Code : "
        }}</span>
        {{
          recommendation.reference_code ? recommendation.reference_code : "-"
        }}
      </p>
      <p v-if="recommendation.name === 'Payment'">
        Customer Name : {{ recommendation.customer_name }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reconcileRecommendation: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    console.log(this.reconcileRecommendation, "data");
  },
};
</script>
